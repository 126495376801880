'use client';

import React, {
    createContext,
    useState,
    useContext,
    ReactNode,
} from 'react';

export const BASE_URL = 'https://crowdsihipbc.online/api';

export interface ReviewInterface {
    id: string;
    author: string;
    recipient: string;
    rating: string;
    text: string;
}

interface ReviewsContextType {
    handleGetReviews: (id: string) => Promise<any>;
}

const ReviewsContext = createContext<ReviewsContextType | undefined>(undefined);

export const ReviewsProvider = ({ children }: { children: ReactNode }) => {

    const handleGetReviews = async (id: string) => {
        try {
            const profile = await fetch(`${BASE_URL}/reviews/`, {
                headers: {
                    "tg-id": id
                }
            }).then(res => res.json());

            if (profile) {
                return profile;
            } else {
                console.error('Failed to fetch Profile');
            }
        } catch (error) {
            console.error('Failed to fetch Profile', error);
        }
    };

    return (
        <ReviewsContext.Provider
            value={{
                handleGetReviews,
            }}
        >
            {children}
        </ReviewsContext.Provider>
    );
};

export const useReviews = (): ReviewsContextType => {
    const context = useContext(ReviewsContext);
    if (context === undefined) {
        throw new Error('useReviews must be used within an ReviewsProvider');
    }
    return context;
};
