import React, { FC, useEffect, useState } from "react";

import { ReactComponent as StarIcon } from "../../assets/icons/star.svg";
import { ReactComponent as AgreementIcon } from "../../assets/icons/agreement.svg";
import { ReactComponent as SupportIcon } from "../../assets/icons/support.svg";
import { ReactComponent as FriendsIcon } from "../../assets/icons/friends.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/arrowRight.svg";

import Navigation from "../../components/Navigation/Navigation";
import Avatar from "../../components/Avatar/Avatar";

import styles from "./profilePage.module.css";
import Reviews from "../../components/Reviews/Reviews";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useProfile } from "../../context/ProfileContext";

// @ts-ignore
const tg = window.Telegram.WebApp;
const ProfilePage: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { profileData, handleGetProfileData } = useProfile();
  const [telegramUserId, setTelegramUserId] = useState(null);
  const hashedTgId = searchParams.get("hashed-tg-id") || "";
  function sendSupportRequest() {
    fetch("https://crowdsihipbc.online/api/users/support/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "tg-id": hashedTgId,
      },
    })
      .then(() => {
        // @ts-ignore
        window.Telegram.WebApp.close();
      })
      .catch((error) => console.error("Error:", error));
  }

  function sendAgreementRequest() {
    fetch("https://crowdsihipbc.online/api/users/agreement/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "tg-id": hashedTgId,
      },
    })
      .then(() => {
        // @ts-ignore
        window.Telegram.WebApp.close();
      })
      .catch((error) => console.error("Error:", error));
  }

 

  async function shareWithFriends() {
    const shareData = {
      title: "MDN",
      text: "Learn web development on MDN!",
      url: "https://developer.mozilla.org",
    };
    try {
      await navigator.share(shareData);
    } catch (err) {
      navigator.clipboard.writeText(shareData.text + " " + shareData.url);
    }
  }

  
  const onEditClick = async () => {
    navigate(
      `/profile/edit?tg-id=${searchParams.get(
        "tg-id"
      )}&hashed-tg-id=${searchParams.get("hashed-tg-id")}`
    );
    // @ts-ignore
    window.Telegram.WebApp.BackButton.show();

    // @ts-ignore
    window.Telegram.WebApp.BackButton.onClick(() => {
      navigate(
        `/profile?tg-id=${searchParams.get(
          "hashed-tg-id"
        )}&hashed-tg-id=${searchParams.get("hashed-tg-id")}`
      );
      // @ts-ignore
      window.Telegram.WebApp.close(); // Закрываем веб-приложение
    });
  };

  useEffect(() => {
    if (searchParams) {
      handleGetProfileData(searchParams.get("hashed-tg-id") as string);
    }
  }, [searchParams]);

  useEffect(() => {
    // Проверяем, доступен ли объект Telegram Web App
    // @ts-ignore
    if (window?.Telegram?.WebApp) {
      // @ts-ignore
      const user = window?.Telegram.WebApp.initDataUnsafe?.user;
      if (user) {
        setTelegramUserId(user.id); // Получаем Telegram ID пользователя
      } else {
        console.error("Пользователь не авторизован");
      }
    } else {
      console.error("Telegram WebApp не инициализирован");
    }
  }, []);
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.content}>
        <div className={styles.profileAvatarWrapper}>
          <Avatar profileName={profileData ? profileData.name : "P"} />
          <div className={styles.topInfoWrapper}>
            <p className={styles.profileName}>
              {profileData ? profileData.name : "-"}
            </p>
            {profileData && profileData?.rating && (
              <p className={styles.rating}>
                <StarIcon />
                <span>{profileData?.rating}</span>
              </p>
            )}
          </div>
          <button onClick={onEditClick} className={styles.edit}>
            Edit
          </button>
        </div>
        <div className={styles.bioBlock}>
          <div className={styles.bio}>
            <p>Bio</p>
            <div className={styles.bioDescription}>
              {profileData ? profileData.bio : "-"}
            </div>
          </div>
          <div className={styles.links}>
            <p>Links</p>
            <a
              target={"_blank"}
              href={profileData ? profileData.social_link : "#"}
            >
              {profileData ? profileData.social_link : "-"}
            </a>
          </div>
        </div>
        <div className={styles.optionalBlock}>
          <div className={styles.optionItem}>
            <FriendsIcon />
            <div className={styles.optionItemBlock} onClick={shareWithFriends}>
              <p>Invite Friends</p>
              <RightArrow />
            </div>
          </div>
          <div className={styles.optionItem}>
            <SupportIcon />
            <div
              className={styles.optionItemBlock}
              onClick={sendSupportRequest}
            >
              <p>Support</p>
              <RightArrow />
            </div>
          </div>
          <div className={styles.optionItem}>
            <AgreementIcon />
            <div
              className={styles.optionItemBlock}
              onClick={sendAgreementRequest}
            >
              <p>User Agreement</p>
              <RightArrow />
            </div>
          </div>
        </div>
      </div>
      <Reviews />
      <Navigation />
    </div>
  );
};

export default ProfilePage;
