import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PageAboutYou } from "./pages/PageAboutYou/PageAboutYou";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import EditProfile from "./pages/EditProfile/EditProfile";
import "./App.css";
import { AdditionalInfoPage } from "./pages/AdditionalInfoPage";
interface RouteConfig {
  path: string;
  element: React.ReactNode;
}

export const ROUTE_PATHS = {
  HOME: "/",
  PROFILE: "/profile",
  EDIT_PROFILE: "/profile/edit",
  ADDITIONAL_INFO: "/additional-info",
} as const;

type PathType = (typeof ROUTE_PATHS)[keyof typeof ROUTE_PATHS];
//@ts-ignore
export const tg = window.Telegram.WebApp;
const routes: RouteConfig[] = [
  { path: ROUTE_PATHS.HOME, element: <PageAboutYou /> },
  { path: ROUTE_PATHS.PROFILE, element: <ProfilePage /> },
  { path: ROUTE_PATHS.EDIT_PROFILE, element: <EditProfile /> },
  { path: ROUTE_PATHS.ADDITIONAL_INFO, element: <AdditionalInfoPage /> },
];

function App() {
  React.useEffect(() => {
    tg.ready();
    tg.expand();
    tg.SettingsButton.show();
    tg.disableVerticalSwipes();
  });

  return (
    <BrowserRouter>
      <Routes>
        {routes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
