import React, { FC, useEffect, useState } from "react";

import { InputWrapper } from "../../components/Input/Input";
import { Button } from "../../components/Button/Button";

import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

import Arrows from "../../assets/icons/arrows.svg";
import IOSStyleDatePicker from "../../components/DatePicker/DatePicker";

import style from "./editProfile.module.css";
import { BASE_URL, useProfile } from "../../context/ProfileContext";
import { useNavigate, useSearchParams } from "react-router-dom";

const option: { title: string; value: string }[] = [
  { title: "Male", value: "male" },
  { title: "Female", value: "female" },
  { title: `Don't want to point`, value: "not_specified" },
];

const formatDateForPicker = (dateString: string): string => {
  const [year, month, day] = dateString.split("-");
  return `${day.padStart(2, "0")}.${month.padStart(2, "0")}.${year}`;
};

function capitalizeFirstLetter(str?: string) {
  if (!str) return str; // Возвращаем строку без изменений, если она пустая
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const EditProfile: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { profileData, handleGetProfileData } = useProfile();
  console.error(profileData?.sex, "rofileData?.sex");
  const [loading, setLoading] = useState(false);
  const [editProfile, setEditProfile] = useState(profileData);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    profileData?.sex || "Gender"
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  const handlePopupOpen = () => setPopupOpen(true);
  const handlePopupClose = () => setPopupOpen(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditProfile((prevState) =>
      prevState ? { ...prevState, social_link: event.target.value } : null
    );
  };

  useEffect(() => {
    if (profileData?.birthdate) {
      const formattedDate = formatDateForPicker(profileData.birthdate);
      setEditProfile((prevState) =>
        prevState ? { ...prevState, birthdate: formattedDate } : null
      );
    }
  }, [profileData]);

  useEffect(() => {
    if (searchParams.get("hashed-tg-id")) {
      handleGetProfileData(searchParams.get("hashed-tg-id") as string);
    }
  }, [searchParams]);

  const handleDateChange = (date: string) => {
    setEditProfile((prevState) =>
      prevState ? { ...prevState, birthdate: date } : null
    );
  };

  const handleProfileUpdate = async () => {
    setLoading(true);
    if (editProfile && searchParams.get("hashed-tg-id")) {
      await fetch(`${BASE_URL}/users/${profileData?.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "tg-id": searchParams.get("hashed-tg-id") as string,
        },
        body: JSON.stringify(editProfile),
      })
        .then((res) => {
          res.json();
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
      await handleGetProfileData(searchParams.get("hashed-tg-id") as string);
      navigate(`/profile?hashed-tg-id=${searchParams.get("hashed-tg-id")}`);
    }
  };

  const handleProfileDelete = async () => {
    setLoading(true);
    if (searchParams.get("hashed-tg-id")) {
      try {
        const res = await fetch(`${BASE_URL}/users/${profileData?.id}/`, {
          method: "DELETE",
          headers: {
            "tg-id": searchParams.get("hashed-tg-id") as string,
          },
        });

        // Проверка, что ответ не пустой и статус успешный
        if (res.ok) {
          const data = await res.json().catch(() => null); // Попытка распарсить JSON, если пусто - вернем null
        } else {
          console.error("Error:", res.statusText);
        }

        setLoading(false);
      } catch (err) {
        console.error(err);
        handleModalClose();
        handlePopupOpen();
        setTimeout(() => {
          handlePopupClose();
        }, 3000);
        setLoading(false);
      }

      await handleGetProfileData(searchParams.get("hashed-tg-id") as string);
      navigate(
        `/?tg-id=${profileData?.tg_id}&hashed-tg-id=${searchParams.get(
          "hashed-tg-id"
        )}`
      );
    }
  };

  return (
    <div>
      <div className={style.mainWrapper}>
        <div className={style.wrapper_input}>
          <p className={style.label}>About you</p>

          <InputWrapper
            type="text"
            placeholder="Name"
            value={editProfile?.name || ""}
            onChange={(name: string) =>
              setEditProfile((prevState) =>
                prevState ? { ...prevState, name } : null
              )
            }
          />

          <div className={style.blockGender} onClick={() => setIsOpen(!isOpen)}>
            <span
              className={
                selectedOption === "Gender"
                  ? style.defaultGender
                  : style.selectedGender
              }
            >
              {option.find((item) => item.value === selectedOption)?.title || 'Gender'}
            
            </span>

            <img src={Arrows} alt="" />

            {isOpen && (
              <div className={style.option}>
                {option.map((item, key) => (
                  <div
                    key={key}
                    className={style.optionItem}
                    onClick={() => {
                      setSelectedOption(item.value);
                      setEditProfile((prevState) =>
                        prevState ? { ...prevState, sex: item.value } : null
                      );
                    }}
                  >
                    <span className={style.optionText}>{item.title}</span>
                  </div>
                ))}
              </div>
            )}
          </div>

          <IOSStyleDatePicker
            initialDate={editProfile?.birthdate}
            onDateChange={handleDateChange}
          />

          <p className={style.label}>Bio</p>
          <textarea
            value={editProfile?.bio || ""}
            onChange={(event) =>
              setEditProfile((prevState) =>
                prevState
                  ? {
                      ...prevState,
                      bio: event.target.value,
                    }
                  : null
              )
            }
            className={style.textArea}
            placeholder="Tell about yourself..."
          ></textarea>

          <p className={style.inputValue}>
            {editProfile?.social_link?.length} / 150
          </p>

          <input
            className={style.input}
            type="text"
            value={editProfile?.social_link || ""}
            onChange={handleInputChange}
            placeholder="instagram.com"
          />
        </div>
        <button onClick={handleModalOpen} className={style.deleteButton}>
          Delete account
        </button>
      </div>
      <footer className={style.footer}>
        <div className="container">
          <div className={style.button}>
            <Button
              text="Done"
              color="fill"
              size="large"
              onClick={handleProfileUpdate}
              disabled={loading}
            />
          </div>
        </div>
      </footer>
      {modalOpen && (
        <div className={style.cover}>
          <div className={style.deleteModal}>
            <h3 className={style.popupTitle}>Delete Account</h3>
            <p>
              Are you sure you want to delete account? This action cannot be
              undone.
            </p>
            <div className={style.buttons}>
              <button
                onClick={() => {
                  handleProfileDelete();
                }}
                className={style.delete}
              >
                Delete
              </button>
              <button onClick={handleModalClose} className={style.cancel}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {popupOpen && (
        <div className={style.deletePopup}>
          <CloseIcon />
          <p>You can't delete account until you have open requests.</p>
        </div>
      )}
    </div>
  );
};

export default EditProfile;
