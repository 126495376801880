// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_input__E5rzA {
  box-sizing: border-box;
  border-radius: 12px;
  padding: 0px 16px;
  width: 100%;
  height: 44px;
  border: none;

  font-family: var(--font-family);
  font-weight: 400;
  font-size: 17px;
  line-height: 129%;
  letter-spacing: -0.02em;
  color: var(--main-text);
}

.Input_input__E5rzA::placeholder {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 17px;
  line-height: 129%;
  letter-spacing: -0.02em;
  color: var(--main-hint);
}

.Input_input__E5rzA:focus {
  border: none;
  outline:none;
}

.Input_input__E5rzA.Input_error__MApdY {
  border: 1px solid #ff4b4b;
}

.Input_errorMessage__XWHMg {
  margin-top: 16px;
  font-size: 14px;
  color: #ff4b4b;
}
`, "",{"version":3,"sources":["webpack://./src/components/Input/Input.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,YAAY;;EAEZ,+BAA+B;EAC/B,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB","sourcesContent":[".input {\n  box-sizing: border-box;\n  border-radius: 12px;\n  padding: 0px 16px;\n  width: 100%;\n  height: 44px;\n  border: none;\n\n  font-family: var(--font-family);\n  font-weight: 400;\n  font-size: 17px;\n  line-height: 129%;\n  letter-spacing: -0.02em;\n  color: var(--main-text);\n}\n\n.input::placeholder {\n  font-family: var(--font-family);\n  font-weight: 400;\n  font-size: 17px;\n  line-height: 129%;\n  letter-spacing: -0.02em;\n  color: var(--main-hint);\n}\n\n.input:focus {\n  border: none;\n  outline:none;\n}\n\n.input.error {\n  border: 1px solid #ff4b4b;\n}\n\n.errorMessage {\n  margin-top: 16px;\n  font-size: 14px;\n  color: #ff4b4b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `Input_input__E5rzA`,
	"error": `Input_error__MApdY`,
	"errorMessage": `Input_errorMessage__XWHMg`
};
export default ___CSS_LOADER_EXPORT___;
