// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__ki\\+rd {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.styles_photoItem__OnbPY {
  position: "relative";
  cursor: pointer;
  height: 160px;
  border-radius: 12px;
  flex-basis: calc(50% - 4px);
  background: #dde8ff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.styles_empty__-utzf {
  position: "relative";
  border: 1px dashed #007aff;
}
.styles_loader__lElvq {
  width: 24px;
  height: 24px;
  border: 3px solid #007aff; /* Светлая часть */
  border-top: 3px solid #dde8ff; /* Тёмная часть */
  border-radius: 50%;
  animation: styles_spin__JwA0g 1s linear infinite;
}

@keyframes styles_spin__JwA0g {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/UploadPhotoBlock/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,QAAQ;AACV;AACA;EACE,oBAAoB;EACpB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,oBAAoB;EACpB,0BAA0B;AAC5B;AACA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB,EAAE,kBAAkB;EAC7C,6BAA6B,EAAE,iBAAiB;EAChD,kBAAkB;EAClB,gDAAkC;AACpC;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 8px;\n}\n.photoItem {\n  position: \"relative\";\n  cursor: pointer;\n  height: 160px;\n  border-radius: 12px;\n  flex-basis: calc(50% - 4px);\n  background: #dde8ff;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  box-sizing: border-box;\n}\n.empty {\n  position: \"relative\";\n  border: 1px dashed #007aff;\n}\n.loader {\n  width: 24px;\n  height: 24px;\n  border: 3px solid #007aff; /* Светлая часть */\n  border-top: 3px solid #dde8ff; /* Тёмная часть */\n  border-radius: 50%;\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__ki+rd`,
	"photoItem": `styles_photoItem__OnbPY`,
	"empty": `styles_empty__-utzf`,
	"loader": `styles_loader__lElvq`,
	"spin": `styles_spin__JwA0g`
};
export default ___CSS_LOADER_EXPORT___;
