// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rating_star__T8zrv {
    width: 16px;
    height: 16px;
}

.rating_filled__JwjbK path {
    fill: #FFCC00;
}

.rating_empty__Zu5Jy path {
    fill: #999999;
}

.rating_rating__m7zSb {
    display: flex;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Rating/rating.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".star {\n    width: 16px;\n    height: 16px;\n}\n\n.filled path {\n    fill: #FFCC00;\n}\n\n.empty path {\n    fill: #999999;\n}\n\n.rating {\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"star": `rating_star__T8zrv`,
	"filled": `rating_filled__JwjbK`,
	"empty": `rating_empty__Zu5Jy`,
	"rating": `rating_rating__m7zSb`
};
export default ___CSS_LOADER_EXPORT___;
