// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation_navigation__bj0Q9 {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-top: 1px solid #54545657;
    display: flex;
    justify-content: space-around;
    background: white;
    padding: 7px 0 24px;
    margin: 0 auto;
    max-width: 475px;
}

.navigation_tabItem__J1Eof {
    cursor: pointer;
}

.navigation_tab__OwKW1 path {
    fill: #999999;
}

.navigation_active__d\\+ER9 path {
    fill: #007AFF;
}
`, "",{"version":3,"sources":["webpack://./src/components/Navigation/navigation.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,OAAO;IACP,QAAQ;IACR,WAAW;IACX,+BAA+B;IAC/B,aAAa;IACb,6BAA6B;IAC7B,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".navigation {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    width: 100%;\n    border-top: 1px solid #54545657;\n    display: flex;\n    justify-content: space-around;\n    background: white;\n    padding: 7px 0 24px;\n    margin: 0 auto;\n    max-width: 475px;\n}\n\n.tabItem {\n    cursor: pointer;\n}\n\n.tab path {\n    fill: #999999;\n}\n\n.active path {\n    fill: #007AFF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": `navigation_navigation__bj0Q9`,
	"tabItem": `navigation_tabItem__J1Eof`,
	"tab": `navigation_tab__OwKW1`,
	"active": `navigation_active__d+ER9`
};
export default ___CSS_LOADER_EXPORT___;
