import React, { useRef, useState } from "react";
import styles from "./styles.module.css";
import PlusIcon from "../../assets/icons/plus.svg";
import FileIcon from "../../assets/icons/file.svg";
import PhotoIcon from "../../assets/icons/photo.svg";
import PhotoLibraryIcon from "../../assets/icons/photoLibrary.svg";
import { conditionalClassNames } from "../../utils/conditionalClassNames";
import { Popover } from "../Popover";

enum PhotoState {
  Empty,
  Loading,
  Loaded,
}

const PhotoItem: React.FC = () => {
  const [statePhoto, setStatePhoto] = useState<PhotoState>(PhotoState.Empty);
  const parentRef = useRef<HTMLDivElement>(null);

  const handleLoadStart = () => {
    setStatePhoto(PhotoState.Loading);
  };

  const handleLoadEnd = () => {
    setStatePhoto(PhotoState.Loaded);
  };

  const classes = conditionalClassNames(
    styles.photoItem,
    statePhoto === PhotoState.Empty && styles.empty
  );
  const handlePhotoLibrary = () => {
    console.log("Photo Library clicked");
  };

  const handleTakePhoto = () => {
    console.log("Take Photo clicked");
  };

  const handleChooseFile = () => {
    console.log("Choose File clicked");
  };
  const popoverItems = [
    {
      text: "Photo Library",
      icon: PhotoLibraryIcon,
      onClick: handlePhotoLibrary,
    },
    { text: "Take Photo", icon: PhotoIcon, onClick: handleTakePhoto },
    { text: "Choose file", icon: FileIcon, onClick: handleChooseFile },
  ];
  return (
    <div className={classes} ref={parentRef}>
      {statePhoto === PhotoState.Loading && <div className={styles.loader} />}
      {statePhoto === PhotoState.Empty && <img src={PlusIcon} alt="PlusIcon" />}
      <Popover parentRef={parentRef} items={popoverItems} />
    </div>
  );
};
export const UploadPhotoBlock: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <PhotoItem />
      <PhotoItem />
      <PhotoItem />
      <PhotoItem />
      <PhotoItem />
    </div>
  );
};
