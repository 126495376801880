import style from "./Button.module.css";
import cn from "classnames";

type Props = {
  color: string;
  text: string;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  size: string;
};

export const Button: React.FC<Props> = ({
  color,
  text,

  disabled = false,
  onClick,
  className,
  size,
}) => {
  return (
    <button
      disabled={disabled}
      className={cn(
        style.button,
        {
          [style.button_fill]: color === "fill",
          [style.button_bezeled]: color === "bezeled",
          [style.button_plain]: color === "plain",
          [style.button_gray]: color === "gray",
          [style.button_outline]: color === "outline",
          [style.button_white]: color === "white",
          [style.button_disabled]: disabled,
        },
        {
          [style.button_small]: size === "small",
          [style.button_medium]: size === "medium",
          [style.button_large]: size === "large",
        }
      )}
      onClick={onClick}
    >
      <span className={style.text}>{text}</span>
    </button>
  );
};
