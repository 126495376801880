import React, { FC } from "react";

import { ReactComponent as SmallStarIcon } from "../../assets/icons/starSmall.svg";

import styles from "./rating.module.css";

interface StarProps {
    filled: boolean
}

interface RatingProps {
    rating: string
}

const Star: FC<StarProps> = ({ filled }) => (
    <SmallStarIcon className={`${filled ? styles.filled : styles.empty}`} />
);

export const Rating: FC<RatingProps> = ({ rating }) => {
    const stars = Array.from({ length: 5 }, (_, index) => (
        <Star key={index} filled={index < +rating} />
    ));

    return <div className={styles.rating}>{stars}</div>;
};