import React, {FC, useState} from 'react';

import { ReactComponent as SendIcon } from "../../assets/icons/send.svg"
import { ReactComponent as DeliverIcon } from "../../assets/icons/deliver.svg"
import { ReactComponent as TrackingIcon } from "../../assets/icons/tracking.svg"
import { ReactComponent as ProfileIcon } from "../../assets/icons/profile.svg"

import styles from "./navigation.module.css";

enum TABS_VALUES {
    send,
    deliver,
    tracking,
    profile
}

const Navigation: FC = () => {
    const [activeTab, setActiveTab] = useState(TABS_VALUES.profile);

    const handleTabChange = (value: TABS_VALUES) => {
        setActiveTab(value)
    }

    return (
        <nav className={styles.navigation}>
            <button onClick={() => handleTabChange(TABS_VALUES.send)} className={styles.tabItem}>
                <SendIcon className={activeTab === TABS_VALUES.send ? styles.active : styles.tab}/>
            </button>
            <button onClick={() => handleTabChange(TABS_VALUES.deliver)} className={styles.tabItem}>
                <DeliverIcon className={activeTab === TABS_VALUES.deliver ? styles.active : styles.tab} />
            </button>
            <button onClick={() => handleTabChange(TABS_VALUES.tracking)} className={styles.tabItem}>
                <TrackingIcon className={activeTab === TABS_VALUES.tracking ? styles.active : styles.tab} />
            </button>
            <button onClick={() => handleTabChange(TABS_VALUES.profile)} className={styles.tabItem}>
                <ProfileIcon className={activeTab === TABS_VALUES.profile ? styles.active : styles.tab} />
            </button>
        </nav>
    );
};

export default Navigation;