import React, { useState } from "react";
import styles from "./styles.module.css";
import GalleryIcon from "./../../assets/icons/gallery.svg";
import { UploadPhotoBlock } from "../../components/UploadPhotoBlock";
// import TelegramPhotoUploader from "../../components/TelegramPhotoUploader/TelegramPhotoUploader";

export const AdditionalInfoPage = () => {
  const [info, setInfo] = useState<string>("");

  const handleInfoChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInfo(e.target.value);
  };
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.content}>
        <div className={styles.info}>
          <img src={GalleryIcon} alt="GalleryIcon" />
          <h2>Provide more information about parcel</h2>
          <p>
            Provide as many details as possible. Specify the weight, size, what
            is inside.
          </p>
        </div>

        <p className={styles.label}>Additional info</p>
        <textarea
          value={info || ""}
          onChange={handleInfoChange}
          className={styles.textArea}
          placeholder="Hey, I need to delivery package for my grandma. Weighs approximately 2 kg, everything fit in a shoebox"
        ></textarea>
        <div className={styles.photos}>
          <h3>Photos</h3>
          <p>Attach a photo for a better understanding of of parcel content. </p>
          <UploadPhotoBlock />
        </div>
      </div>
    </div>
  );
};
