// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_popover__NwCZk {
  width: 200px;
  border-radius: 12px;
  background-color: #ffffff;
}

.styles_popoverItem__rICWn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;

  text-align: left;
}

.styles_popoverItem__rICWn:not(:last-child) {
  border-bottom: 0.5px solid #54545657;
}

.styles_popoverIcon__LG287 {
  margin-right: 8px;
}

.styles_popoverText__vye8U {
  flex-grow: 1;
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Popover/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;;EAEjB,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".popover {\n  width: 200px;\n  border-radius: 12px;\n  background-color: #ffffff;\n}\n\n.popoverItem {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 12px 16px;\n  cursor: pointer;\n  width: 100%;\n  text-align: left;\n  font-size: 17px;\n  font-weight: 400;\n  line-height: 22px;\n\n  text-align: left;\n}\n\n.popoverItem:not(:last-child) {\n  border-bottom: 0.5px solid #54545657;\n}\n\n.popoverIcon {\n  margin-right: 8px;\n}\n\n.popoverText {\n  flex-grow: 1;\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover": `styles_popover__NwCZk`,
	"popoverItem": `styles_popoverItem__rICWn`,
	"popoverIcon": `styles_popoverIcon__LG287`,
	"popoverText": `styles_popoverText__vye8U`
};
export default ___CSS_LOADER_EXPORT___;
