// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar_avatar__h04vV {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

}

.avatar_s__-6uSQ {
    min-width: 40px;
    width: 40px;
    height: 40px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
}

.avatar_l__DDziJ {
    min-width: 64px;
    width: 64px;
    height: 64px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/Avatar/avatar.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;;AAEhB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".avatar {\n    border-radius: 50%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: white;\n\n}\n\n.s {\n    min-width: 40px;\n    width: 40px;\n    height: 40px;\n    font-size: 18px;\n    line-height: 22px;\n    font-weight: 600;\n}\n\n.l {\n    min-width: 64px;\n    width: 64px;\n    height: 64px;\n    font-size: 24px;\n    line-height: 28px;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": `avatar_avatar__h04vV`,
	"s": `avatar_s__-6uSQ`,
	"l": `avatar_l__DDziJ`
};
export default ___CSS_LOADER_EXPORT___;
