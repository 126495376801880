import React, { useState, useRef, useEffect } from "react";
import styles from "./styles.module.css";

interface PopoverItem {
  text: string;
  icon: string;
  onClick: () => void;
}

interface PopoverProps {
  items: PopoverItem[];
  parentRef: React.RefObject<HTMLDivElement>;
}
export const Popover: React.FC<PopoverProps> = ({ items, parentRef }) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const popoverRef = useRef<HTMLDivElement>(null);

  const handleClick = (event: MouseEvent) => {
    console.log(window.scrollY, "parentRef.current.scrollTop");

    if (parentRef.current) {
      const popover = popoverRef?.current?.getBoundingClientRect();
      const parentRect = parentRef.current.getBoundingClientRect();
      const top = parentRect.top + window.scrollY + event.offsetY;
      const left =
        parentRect.left + parentRef.current.scrollLeft + event.offsetX;
      if (
        !(
          event.clientX > left &&
          event.clientX < left + (popover?.width || 0) &&
          event.clientY > top + window.scrollY &&
          event.clientY < top + window.scrollY + (popover?.height || 0)
        )
      ) {
        event.stopPropagation();
        setPosition({ top, left });
        setVisible(true);
      }
    }
  };

  const handleClose = () => {
    setVisible(false);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target as Node)
    ) {
      handleClose();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node) &&
        !parentRef.current?.contains(event.target as Node)
      ) {
        setVisible(false); // Закрытие поповера при клике вне его области
      }
    };

    if (parentRef.current) {
      parentRef.current.addEventListener("click", handleClick);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      if (parentRef.current) {
        parentRef.current.removeEventListener("click", handleClick);
      }
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [parentRef]);

  const handleItemClick =
    (item: PopoverItem) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      item.onClick();
      setVisible(false);
    };

  return (
    <>
      {visible && (
        <div
          className={styles.popover}
          ref={popoverRef}
          style={{
            top: position.top,
            left: position.left,
            position: "absolute",
          }}
        >
          {items.map((item, index) => (
            <button
              key={index}
              className={styles.popoverItem}
              onClick={handleItemClick(item)}
            >
              <span className={styles.popoverText}>{item.text}</span>
              <img src={item.icon} alt={item.text} />
            </button>
          ))}
        </div>
      )}
    </>
  );
};
