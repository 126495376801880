import React, {FC, useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";

import ReviewsList from "./ReviewsList/ReviewsList";

import {ReviewInterface, useReviews} from "../../context/ReviewsContext";
import {ReactComponent as EmojiIcon} from "../../assets/icons/emoji.svg";

import styles from "./reviews.module.css";

enum TABS_VALUES {
    received,
    given,
}

const Reviews: FC = () => {
    const [searchParams] = useSearchParams();

    const { handleGetReviews } = useReviews();
    const [activeTab, setActiveTab] = useState(TABS_VALUES.received);
    const [loading, setLoading] = useState(false);
    const [reviewsData, setReviewsData] = useState<ReviewInterface[]>([]);

    const handleFetchReviews = async () => {
        setLoading(true);
        const data = await handleGetReviews(searchParams.get("hashed-tg-id") as string);

        setReviewsData(data);
        setLoading(false);
    }

    const handleTabChange = async (value: TABS_VALUES) => {
        setLoading(true);

        setActiveTab(value)
        setReviewsData([]);
        await handleFetchReviews();
        setLoading(false);
    }

    useEffect(() => {
        if (searchParams.get("hashed-tg-id")) {
            handleFetchReviews();
        }
    }, [searchParams]);

    return (
        <div className={styles.reviews}>
            <div className={styles.reviewsTabs}>
                <div onClick={() => handleTabChange(TABS_VALUES.received)} className={`${styles.tabItem} ${activeTab === TABS_VALUES.received ? styles.active : ""}`}>
                    <p>Received</p>
                    {activeTab === TABS_VALUES.received && (<span className={styles.activeBottom}></span>)}
                </div>
                <div onClick={() => handleTabChange(TABS_VALUES.given)} className={`${styles.tabItem} ${activeTab === TABS_VALUES.given ? styles.active : ""}`}>
                    <p>Given</p>
                    {activeTab === TABS_VALUES.given && (<span className={styles.activeBottom}></span>)}
                </div>
            </div>
            {loading ? (
                <div className={styles.noReviews}>
                    <EmojiIcon />
                    <p className={styles.emptyReviews}>We are loading your reviews...</p>
                </div>
            ) : activeTab === TABS_VALUES.given ? (
                <ReviewsList reviews={reviewsData} />
            ) : (
                <ReviewsList reviews={reviewsData} />
            )}

        </div>
    );
};

export default Reviews;
