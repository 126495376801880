import { useEffect, useRef, useState } from "react";
import { Button } from "../../components/Button/Button";
import MainImg from "./../../assets/icons/shippingbox.svg";
import Arrows from "./../../assets/icons/arrows.svg";

import style from "./PageAboutYou.module.css";
import IOSStyleDatePicker from "../../components/DatePicker/DatePicker";
import { useNavigate, useSearchParams } from "react-router-dom";

const option: { title: string; value: string }[] = [
  { title: "Male", value: "male" },
  { title: "Female", value: "female" },
  { title: `Don't want to point`, value: "not_specified" },
];

export const PageAboutYou = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tgId = searchParams.get("tg-id");
  const hashedTgId = searchParams.get("hashed-tg-id");
  // const tgId = '-1';
  // const hashedTgId = 'pbkdf2_sha256$870000$ABtMqvV5nU3h30x08hFmzM$nfWfBbrkp4WhUKkZntTIKT3MD613QqThpu94yUGMTGM=';
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Gender");
  const [inputValue, setInputValue] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [bio, setBio] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<string | null>(null);
  const bioRef = useRef<HTMLTextAreaElement>(null);
  const instagramRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const checkUserExists = async () => {
      try {
        const response = await fetch(`https://crowdsihipbc.online/api/users/`, {
          headers: {
            "Content-Type": "application/json",
            "tg-id": hashedTgId || "",
          },
        });

        if (response.ok) {
          const data = await response.json();
          navigate(`/profile?hashed-tg-id=${hashedTgId}`);
        } else {
          navigate(`/?tg-id=${tgId}&hashed-tg-id=${hashedTgId}`);
        }
      } catch (error) {
        console.error("Error checking user existence:", error);
      }
    };
    checkUserExists();
  }, [hashedTgId, navigate]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleBioChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBio(event.target.value);
  };
  const handleFocus = (ref: React.RefObject<HTMLElement>) => {
    if (ref.current) {
      setTimeout(() => {
        if (ref.current) {
          window.scrollBy({
            top: window.innerHeight,
            behavior: "smooth",
          });
        }
      }, 1500);
    }
  };
  const handleDateChange = (date: string) => {
    setDateOfBirth(date);
  };

  const handleSubmit = async () => {
    const formData = {
      name,
      sex: selectedOption,
      bio,
      social_link: inputValue,
      birthdate: dateOfBirth,
      tg_id: tgId,
      hashed_tg_id: hashedTgId,
    };

    try {
      const response = await fetch(
        "https://crowdsihipbc.online/api/users/register/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      navigate(`/profile?hashed-tg-id=${hashedTgId}`);
      console.log("Form submitted successfully!");
    } catch (error) {
      console.error("There was a problem with the submission:", error);
    }
  };

  return (
    <>
      <div className="container">
        <div className={style.mainWrapper}>
          <div className={style.wrapper}>
            <img src={MainImg} alt="" className={style.mainImg} />
            <h1 className={style.title}>Deliver my pack</h1>
            <p className={style.text}>
              A service for finding people who can pick up and deliver your
              parcel anywhere in the world
            </p>
          </div>

          <div className={style.wrapper_input}>
            <p className={style.label}>About you</p>

            <input
              className={style.input}
              type="text"
              value={name}
              onChange={handleNameChange}
              placeholder="Name"
            />

            <div
              className={style.blockGender}
              onClick={() => setIsOpen(!isOpen)}
            >
              <span
                className={
                  selectedOption === "Gender"
                    ? style.defaultGender
                    : style.selectedGender
                }
              >
                {option.find((item) => item.value === selectedOption)?.title || 'Gender'}
              </span>

              <img src={Arrows} alt="" />

              {isOpen && (
                <div className={style.option}>
                  {option.map((item, key) => (
                    <div
                      key={key}
                      className={style.optionItem}
                      onClick={() => setSelectedOption(item.value)}
                    >
                      <span className={style.optionText}>{item.title}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <IOSStyleDatePicker onDateChange={handleDateChange} />

            <p className={style.label}>Bio</p>
            <textarea
              ref={bioRef}
              className={style.textArea}
              placeholder="Tell about yourself..."
              onChange={handleBioChange}
              onFocus={() => handleFocus(bioRef)}
            ></textarea>

            <p className={style.inputValue}>{inputValue.length} / 150</p>

            <input
              ref={instagramRef}
              className={style.input}
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onFocus={() => handleFocus(instagramRef)}
              placeholder="instagram.com"
            />
          </div>
        </div>
      </div>
      <footer className={style.footer}>
        <div className="container">
          <div className={style.button}>
            <Button
              text="Start"
              color="fill"
              size="large"
              onClick={handleSubmit}
              disabled={!name.trim() || selectedOption === "Gender"}
            />
          </div>
        </div>
      </footer>
    </>
  );
};
