import React, { useState, useEffect } from 'react';
import WheelPicker from 'react-wheelpicker';
import styled, { createGlobalStyle } from 'styled-components';
import styles from './DatePicker.module.css';

import ArrowRight from './../../assets/icons/arrowRight.svg';
import ArrowRightActive from './../../assets/icons/arrowRightBlue.svg';

const GlobalStyle = createGlobalStyle`
  #day-picker .scroll-item.selected-time {
    background: #7878801F;
    min-width: 75px;
    border-radius: 8px 0 0 8px;
    box-sizing: border-box;
    font-weight: 400;
    width: 100% !important;
  }

  .scroll-item {
    box-sizing: border-box;
    border: none;
    background: white;
    margin: 0;
    min-width: 75px;
    width: 100% !important;
  }
`;

const GlobalStyle2 = createGlobalStyle`
  #month-picker .scroll-item.selected-time {
    background: #7878801F;
    font-weight: 400;
    min-width: 75px;
    width: 100% !important;
  }
`;

const GlobalStyle3 = createGlobalStyle`
  #year-picker .scroll-item.selected-time {
    background: #7878801F;
    box-sizing: border-box;
    border-radius: 0px 8px 8px 0px;
    font-weight: 400;
    min-width: 75px;
    width: 100% !important;
  }
`;

interface DatePickerProps {
  initialDate?: string;
  onDateChange?: (date: string) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({initialDate, onDateChange}) => {
  const [isOpen, setIsOpen] = useState(false);

  const days = Array.from({length: 31}, (_, i) => (i + 1).toString());
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const currentYear = new Date().getFullYear();
  const years = Array.from({length: currentYear - 1900 + 1}, (_, i) =>
    (1900 + i).toString()
  );

  const parseInitialDate = (dateString: string) => {
    const [year, month, day] = dateString.split('-');
    return {
      day: day,
      month: months[parseInt(month) - 1],
      year: year,
    };
  };
  console.log(initialDate && JSON.stringify(parseInitialDate(initialDate)), 'parseInitialDate(initialDate)')
  const initialParsedDate = initialDate ? parseInitialDate(initialDate) : null;

  const [selectedDay, setSelectedDay] = useState(
    initialParsedDate ? initialParsedDate.day : ''
  );
  const [selectedMonth, setSelectedMonth] = useState(
    initialParsedDate ? initialParsedDate.month : ''
  );
  const [selectedYear, setSelectedYear] = useState(
    initialParsedDate ? initialParsedDate.year : ''
  );

  const formatDate = (): string => {
    // Проверяем, что все значения инициализированы
    if (!selectedDay || !selectedMonth || !selectedYear) {

      return ''; // Возвращаем пустую строку, если значения еще не инициализированы
    }

    const day = selectedDay.padStart(2, '0');
    const monthIndex = months.indexOf(selectedMonth) + 1;
    const month = monthIndex.toString().padStart(2, '0');
    return `${day}.${month}.${selectedYear}`;
  };

  useEffect(() => {
    if (selectedDay && selectedMonth && selectedYear) {
      onDateChange && onDateChange(formatDate());
    }
  }, [selectedDay, selectedMonth, selectedYear]);

  return (
    <div style={{position: 'relative'}}>
      <div className={styles.dateBlock} onClick={() => setIsOpen(!isOpen)}>
        <p className={styles.textDate}>Date of birth</p>
        <div className={styles.wrapperDate}>
          <p className={isOpen ? styles.numDateActive : styles.numDate}>
            {' '}
            {formatDate()}
          </p>
          {isOpen ? (
            <img src={ArrowRightActive} alt=""/>
          ) : (
            <img src={ArrowRight} alt=""/>
          )}
        </div>
      </div>

      {isOpen && (
        <div
          className={styles.wrapper}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none',
            position: 'absolute',
          }}
        >
          <GlobalStyle/>
          <WheelPicker
            data={days}
            scrollerId="day-picker"
            height={40}
            parentHeight={200}
            defaultSelection={days.indexOf(selectedDay)}
            updateSelection={(index) => setSelectedDay(days[index])}
            fontSize={18}
            animation="wheel"
          />
          <GlobalStyle2/>
          <WheelPicker
            data={months}
            scrollerId="month-picker"
            height={40}
            parentHeight={200}
            defaultSelection={months.indexOf(selectedMonth)}
            updateSelection={(index) => setSelectedMonth(months[index])}
            fontSize={18}
            animation="wheel"
          />
          <GlobalStyle3/>
          <WheelPicker
            animation="wheel"
            data={years}
            scrollerId="year-picker"
            height={40}
            parentHeight={200}
            defaultSelection={years.indexOf(selectedYear)}
            updateSelection={(index) => setSelectedYear(years[index])}
            fontSize={18}
          />
        </div>
      )}
      <p className={styles.infoText}>
        Date of birth is an optional field, we will not show it to other
        users.
      </p>
    </div>
  );
};

export default DatePicker;
