import React, { FC, HTMLInputTypeAttribute, useState } from "react";

import styles from "./Input.module.css";

interface InputErrorProps {
  message: string;
  hasError: boolean;
}

interface InputWrapperProps {
  placeholder: string;
  error?: InputErrorProps;
  type: HTMLInputTypeAttribute;
  onChange: (val: string) => void;
  classLabel?: string;
  value?: string;
}

export const InputWrapper: FC<InputWrapperProps> = ({
  type,
  placeholder,
  onChange,
                                                      value = "",
  error = { message: "", hasError: false },
}) => {
  return (
    <>
      <input
        type={type}
        value={value}
        className={`${styles.input} ${error.hasError && styles.error}`}
        placeholder={placeholder}
        onChange={(event) => onChange(event.target.value)}
        required
      />

      {error.hasError && <p className={styles.errorMessage}>{error.message}</p>}
    </>
  );
};
