import React, { FC } from 'react';
import styles from '../reviews.module.css';
import Avatar, { AvatarSizes } from '../../Avatar/Avatar';
import { Rating } from '../../Rating/Rating';
import { ReviewInterface } from '../../../context/ReviewsContext';

import { ReactComponent as EmojiIcon } from '../../../assets/icons/emoji.svg';

interface ReviewsListProps {
  reviews: ReviewInterface[]
}

const ReviewsList: FC<ReviewsListProps> = ({reviews}) => {
  return (
    <div className={styles.reviewsList}>
      {Array.isArray(reviews) && !!reviews.length ? reviews.map(review => (
        <div key={review.id} className={styles.reviewItem}>
          <Avatar profileName={review.author} size={AvatarSizes.s}/>
          <div className={styles.rewiewInfo}>
            <p className={styles.reviewerName}>{review.author}</p>
            <div className={styles.reviewRating}>
              <Rating rating={review.rating}/>
              {/*<div className={styles.date}>2 weeks ago</div>*/}
            </div>
            <div className={styles.reviewText}>
              {review.text}
            </div>
          </div>
        </div>
      )) : (
        <div className={styles.noReviewsWrapper}>
          <div className={styles.noReviews}>
            <EmojiIcon/>
            <p className={styles.emptyReviews}>No reviews yet.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewsList;
